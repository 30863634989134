const firebaseConfig = {
    apiKey: "AIzaSyBZP0jrXVhmIcUwexFbrpTvpkFdIwD153c",
    authDomain: "todo-list-3410d.firebaseapp.com",
    databaseURL: "https://todo-list-3410d.firebaseio.com",
    projectId: "todo-list-3410d",
    storageBucket: "todo-list-3410d.appspot.com",
    messagingSenderId: "642124068599",
    appId: "1:642124068599:web:0154e1991a613051d6c5f2",
    measurementId: "G-7E0EEB073E"
};

export default firebaseConfig;